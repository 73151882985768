body {
    background-color: #e1ebe0;
    color: #0e0b07;
}

.button-main {

    height: 3.2em;
    width: 9em;
    size: 20pt;

    border: 1px solid #c5c5c5;
    border-radius: 10px;
    color: black;

    background-color: rgba(255, 255, 255, 0.616);

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-weight: 500;
    font-size: 1.01em;

    padding: 0.5em;
}

.button-main:hover {

    background-color: #e7e7e7d2;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
}

.product-presentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-name {
    font-size: 4rem;
}

.product-feature {
    font-size: 1.5rem;
    line-height: 1.5em;
    margin: 0;
}

.border-main {
    border: 1px solid white;
}

.descriptions {
    font-size: 0.7em;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: .5rem;
    padding: 1rem;
    overflow: hidden;
}

.share-url {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: 1rem;
    margin-block: 1rem;
    overflow: hidden;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    width: 100%;
    background-color: transparent;
    padding-block: 0.5rem;
    gap: 1.5rem;
}

@media (max-height: 700px) {
    .footer {
        display: none;
    }
}

.footer-item {
    color: rgba(0, 0, 0, 0.35);
    text-decoration: none;
}

.footer-item-small {
    color: rgba(0, 0, 0, 0.35);
    size: small;
}

.footer-item>a {
    color: rgba(0, 0, 0, 0.35);
    text-decoration: none;
}

.card-stack {
    background-color: transparent;
    width: 400px;
    height: 300px;
    display: grid;
    place-items: center;
    position: relative;
    margin: 1rem;
}

.card {
    width: 100%;
    height: 76.25%;
    border: 1px solid black;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    position: absolute;
}



.card-buy-coffee {
    background-color: #3d9011;
    color: white;
}

.card-discovering-items {
    background-color: #FFF8B4;
}

.card-items-to-receive {
    background-color: #FFC8A2;
}

.card-items-to-send {
    background-color: #80E3D1;
}

.card-message {
    background-color: #FFA9A3;
}

.card-progress {
    background-color: #E7B5FF;
}

.card-select-files-to-send {
    background-color: #FFF8B4;
}

.card-share-url {
    background-color: #B5E9AC;
}

.card-items-selection {
    background-color: #F5DEB3;
}

.card-items-description {
    background-color: #BDFCC9;
}

.card-share-link {
    background-color: #b7ebed;
}

.card-text-h1 {
    font-size: 1.2em;
    font-weight: bold;
}

.card-text-h2 {
    font-size: 1.0em;
    font-weight: 500;
}

.card-text-h3 {
    font-size: 1.0em;
    font-weight: normal;
}

.card-text-h4 {
    font-size: 0.8em;
    font-weight: normal;
}

.share-link {
    word-break: break-all;
    word-wrap: break-word;
    font-size: 0.8em;
}

.mt-8 {
    margin-top: 2em;
}

.mt-4 {
    margin-top: 1em;
}

.mb-4 {
    margin-bottom: 1em;
}

.card-rot-1 {
    animation: spinFade-1 1s ease-in-out forwards;
}

.card-rot-2 {
    animation: spinFade-2 1s ease-in-out forwards;
}

.card-rot-3 {
    animation: spinFade-3 1s ease-in-out forwards;
}

.card-rot-4 {
    animation: spinFade-4 1s ease-in-out forwards;
}

.card-rot-5 {
    animation: spinFade-5 1s ease-in-out forwards;
}

@keyframes spinFade-1 {
    0% {
        opacity: 0;
        transform: rotate(6deg);
    }

    100% {
        opacity: 1;
        transform: rotate(-6deg);
    }
}

@keyframes spinFade-2 {
    0% {
        opacity: 0;
        transform: rotate(9deg);
    }

    100% {
        opacity: 1;
        transform: rotate(-3deg);
    }
}

@keyframes spinFade-3 {
    0% {
        opacity: 0;
        transform: rotate(12deg);
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}

@keyframes spinFade-4 {
    0% {
        opacity: 0;
        transform: rotate(15deg);
    }

    100% {
        opacity: 1;
        transform: rotate(3deg);
    }
}

@keyframes spinFade-5 {
    0% {
        opacity: 0;
        transform: rotate(18deg);
    }

    100% {
        opacity: 1;
        transform: rotate(6deg);
    }
}